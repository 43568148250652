import { BrowserTracing } from '@sentry/tracing'
import * as Sentry from '@sentry/vue'
import router from '@/ui/router'

export default {
  install: (app) => {
    if (process.env.NODE_ENV !== 'production') return

    const RELATED_ERROR = 'related error'
    const FLOW_INFO = 'Additional Flow Information'
    const AXIOS_ERROR = 'AxiosError'

    Sentry.init({
      app,
      dsn: process.env.SENTRY_DSN,
      environment: process.env.ENV_CONFIG,
      integrations: [
        new BrowserTracing({
          routingInstrumentation: Sentry.vueRouterInstrumentation(router),
          tracingOrigins: ['localhost', 'locoia.com', /^\//],
        }),
        // Replays disabled due to performance issues. https://github.com/getsentry/sentry-javascript/issues/6946
        // new Sentry.Replay(),
      ],
      ignoreErrors: ['ResizeObserver loop limit exceeded', 'The user is not authenticated'],
      // TODO: remove once Cypress has its own env config
      ignoreUrls: process.env.NODE_ENV === 'production' ? [/localhost/] : [],

      beforeSend(event, hint) {
        const error = hint.originalException

        if (!shouldSendEvent(error)) return null

        if (!error) return event

        // enrich our event if we have a related error attached to our exception
        if (error.relatedError) {
          event.contexts[RELATED_ERROR] = {
            message: error.relatedError.message,
            stack: error.relatedError.stack,
          }
        }

        /** Axios errors don't provide much information by themselves
         * https://github.com/getsentry/sentry-javascript/issues/4377
        */
        const isAxiosErrorWithData = error.name === AXIOS_ERROR && !!error.response?.data
        if (isAxiosErrorWithData) {
          event.contexts[AXIOS_ERROR] = { info: error.response.data }
        }

        if (error.flowId) {
          event.contexts[FLOW_INFO] = {
            flowId: error.flowId,
          }
        }

        return event
      },
    })
    Sentry.setContext(RELATED_ERROR, {
      message: null,
      stack: null,
    })
    Sentry.setContext(FLOW_INFO, {
      flowId: null,
    })
    Sentry.setContext(AXIOS_ERROR, {
      info: null,
    })
  },
}

/**
 * Essentially the equivalent of `ignoreErrors` property.
 * Except this validates special conditions where we don't have certainty that the message of the error will match our requirements
 * @param { Error | string | null} originalException
 */
function shouldSendEvent(originalException) {
  const isUnauthorizedApiResponse = originalException?.response?.status === 401
  return !isUnauthorizedApiResponse && !isAbortedRequest(originalException)
}

function isAbortedRequest(error) {
  return error?.name === 'AxiosError' && error?.message === 'Request aborted'
}
