<template>
  <div style="position: relative;width: 100%;">
    <autocomplete
      v-if="hasAutocomplete"
      ref="autocomplete"
      :flow-context="flowContext"
      :current-connector="currentConnector"
      :selected-connector-auth="selectedConnectorAuth"
      :remote-search-params="getRemoteSearchParams()"
      :remote-search-function="remoteSearchFunction"
      :input-ref="elementKey"
    >
      <editor-input
        :ref="elementKey"
        v-model="model"
        :current-connector="currentConnector"
        :placeholder="placeholder"
        :selected-connector-auth="selectedConnectorAuth"
        @focus="onFocus(true)"
        @blur="onFocus(false)"
        @caret-updated="onFocus(true)"
        @added-autocomplete-options="additionalAutocompleteOptions = $event"
      />
    </autocomplete>
    <el-input
      v-else
      :ref="elementKey"
      v-model="model"
      :type="entry?.type"
      :placeholder="placeholder"
      :show-password="entry?.type === 'password'"
      :size="isEmbed ? 'large' : 'default'"
      @focus="onFocus(true)"
      @blur="onFocus(false)"
    />
  </div>
</template>

<script>
import Autocomplete from '@/ui/components/Editor/Autocomplete/Autocomplete.vue'
import EditorInput from '@/ui/components/Editor/EditorInput/index.vue'
import { translateSchemaItem } from '@/ui-libs/schema-form-renderer/lang/index.js'

export default {
  name: 'TextField',
  components: {
    Autocomplete,
    EditorInput,
  },
  inject: {
    flowContext: {
      default: /** @type {SchemaFormRendererConfig} */ ({}),
    },
    remoteSearchFunction: {
      default: /** @type {Function} */ (() => {}),
    },
    i18n: {
      default: /** @type {Object} */ ({}),
    },
  },
  props: {
    entry: {
      type: Object,
      default: null,
    },
    ruleModel: {
      type: Object,
      required: true,
    },
    elementKey: {
      type: String,
      default: '',
    },
    element: {
      type: Object,
      default: null,
    },
    originalRuleModel: {
      type: Object,
      default: null,
    },
    currentConnector: {
      type: Object,
      default: null,
    },
    selectedConnectorAuth: {
      type: Object,
      default: null,
    },
    expandListIndex: {
      type: Number,
      default: null,
    },
  },
  emits: [
    'ruleModelChange',
    'fieldFocused',
  ],
  data() {
    return {
      isEmbed: process.env.APPLICATION_TYPE === 'Embed',
      fieldFocused: false,
    }
  },
  computed: {
    placeholder() {
      return translateSchemaItem(this.i18n.locale, this.entry.placeholder) || ''
    },
    model: {
      get() {
        return this.ruleModel[this.elementKey]
      },
      set(newData) {
        this.$emit('ruleModelChange', {
          value: newData,
          expandListIndex: this.expandListIndex,
        })
      },
    },
    hasAutocomplete() {
      return ![
        'email',
        'password',
      ].includes(this.entry.type)
    },
  },
  methods: {
    async onFocus(isFocused) {
      this.fieldFocused = isFocused
      const editorRef = isFocused ? this.$refs[this.elementKey]?.$refs?.editorRef : null
      this.$emit('fieldFocused', editorRef)

      /** @type {AutocompleteExposed} */
      const autocomplete = this.$refs.autocomplete
      await autocomplete?.searchSuggestions(editorRef)
    },
    getRemoteSearchParams() {
      if (!this.element?.hasRemoteSearch || !this.element?.remoteSearchParam) {
        return null
      }

      const remoteSearchParams = this.element.remoteSearchParam ?? []
      const result = remoteSearchParams.reduce((acc, item) => {
        acc[item] = this.originalRuleModel[item]
        return acc
      }, {})

      result.resource_type = this.element.resourceType
      result.count = this.element.count
      result.auth = this.selectedConnectorAuth

      return result
    },
  },
}
</script>
