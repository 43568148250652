import router from '@/ui/router'
import hasAccess from './hasAccess'
import accessibleRoutes from './accessibleRoutes'

export default {
  namespaced: true,
  state: () => ({
    routers: [],
    permissions: {},
  }),
  getters: {
    checkPermission: (state, _, __, rootGetters) => (permissionName, roles = undefined) => {
      const userRoles = rootGetters['user/roles']
      return hasAccess(userRoles, state.permissions, { permissionName, roles })
    },
    permissions: state => state.permissions,
  },
  actions: {
    GenerateRoutes({ commit }, { roles, viewPermissions }) {
      if (!roles) {
        throw new Error('Expecting an object with a \'roles\' property. ')
      }

      if (roles.length !== 0) {
        const routes = accessibleRoutes(roles, viewPermissions)
        commit('SET_ROUTERS', routes)
      }
    },
  },
  mutations: {
    SET_ROUTERS: (state, { constRoutes }) => {
      state.routers = constRoutes
    },
    SET_PERMISSIONS: (state, { viewPermissions }) => {
      state.permissions = viewPermissions
    },
  },
}
