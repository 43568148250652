import { companyApi } from '@/ui/api/company'
import { Colors } from '@/ui/consts/colors'
import { loadAllResourcePages } from '@/ui/utils/request'

export default {
  namespaced: true,
  state: () => ({
    company: null,
    companies: [],
    isFetchingCompany: false,
  }),
  getters: {
    isFetchingCompany: (state) => state.isFetchingCompany,
    getCompany: (state) => state.company,
    getCompanyStyles: (state) => {
      if (!state.company || !state.company.styles) {
        return {
          primaryColor: Colors.defaultPrimaryColor,
        }
      }
      if (!state.company.styles.primaryColor) {
        state.company.styles.primaryColor = Colors.defaultPrimaryColor
      }
      return state.company.styles
    },
  },
  actions: {
    async fetchCompany({ commit }, id) {
      commit('SET_IS_FETCHING_COMPANY', true)
      let company
      try {
        company = (await companyApi.get(id))?.data
      } catch (error) {
        return false
      } finally {
        commit('SET_IS_FETCHING_COMPANY', false)
      }
      commit('SET_COMPANY', company)
      return true
    },
    async fetchCompanies({ commit }) {
      const items = []
      const addItems = (newItems) => items.push(...newItems)
      await loadAllResourcePages(companyApi.list, addItems)
      commit('SET_COMPANIES', items)
    },
    async updateCompany({ commit }, company) {
      const { data } = await companyApi.update(company)
      commit('SET_COMPANY', data)
    },
    setCompanyName({ commit }, companyName) {
      commit('EXTEND_COMPANY_PROPERTIES', { name: companyName })
    },
    setCompanyLogo({ commit }, companyLogo) {
      commit('EXTEND_COMPANY_PROPERTIES', { image_url: companyLogo })
    },
  },
  mutations: {
    SET_COMPANY: (state, company) => {
      state.company = company
    },
    SET_COMPANIES: (state, companies) => {
      state.companies = companies
    },
    SET_IS_FETCHING_COMPANY: (state, isFetchingCompany) => {
      state.isFetchingCompany = isFetchingCompany
    },
    EXTEND_COMPANY_PROPERTIES: (state, model) => {
      state.company = { ...state.company, ...model }
    },
  },
}
