<template>
  <div @focusin="handleEditorFocus">
    <editor
      ref="editorRef"
      v-model="value"
      v-bind="$attrs"
      :is-input-ui="true"
      :mode="LanguageMode.Json"
    />
  </div>
</template>

<script>
import Editor from '@/ui/components/Editor/index.vue'
import { LanguageMode } from '@/ui/components/Editor/const'

export default {
  name: 'EditorInput',
  components: {
    Editor,
  },
  inject: ['i18n'],
  props: {
    modelValue: {
      type: String,
      default: '',
    },
  },
  emits: [
    'update:modelValue',
  ],
  data() {
    return {
      LanguageMode,
    }
  },
  computed: {
    value: {
      get() {
        return this.modelValue
      },
      set(value) {
        this.$emit('update:modelValue', value)
      },
    },
  },
  methods: {
    /**
     * This handling exists mainly for the case where pressing "Tab" to move into the next field should select all the text.
     * However, this is also triggered when clicking on any padding present around the editor.
     *
     * Clicking in the editor input field itself, does not highlight all the text.
     * This is because CodeMirror editor sets the caret position when clicking into the field.
     * You can't simultaneously have all text selected and a caret position in the text.
     */
    handleEditorFocus() {
      /** @type {EditorExposed} */
      const editor = this.$refs.editorRef
      editor.selectAllText()
    },
  },
}
</script>
