import * as R from 'ramda'
import { inject } from 'vue'
import { SchemaFormModelTypes } from './SchemaFormModelTypes.js'

/** This function should only be used within the SchemaFormRenderer plugin, as it requires the specific SFR I18n instance
 * Due to this requirement it must be initialized in the vue `setup` lifecycle
*/
export function useSchemaFormModelHelper() {
  const i18n = inject('schemaFormRendererI18n')

  /**
   * @param {object} schema - The schema that will be used to hold the data from `persistedModel`
   * @param {object} previousSchema - The schema that was being used before any changes were made to it (by changing the action)
   * @param {object} persistedModel - The data that was saved using the `previousSchema`. For example the user filled values of a FormHelper.
   * @param {boolean} shouldUseDefaultValues - Whether we will use the default value defined within the `schema` for the field
   * @param {Array<string>} keys - All the properties that need to be accessed to reach the current `schema` value
   * @returns The value that will populate the current `schema`. Uses existing value, or null if types don't match.
  */
  function createFormModel(schema, previousSchema, persistedModel, shouldUseDefaultValues = false, keys = []) {
    previousSchema = previousSchema ?? schema
    const schemaType = schema?.type

    /** schemaType is an object when the key is named "type" but it's defining the key of the field and not the actual field type like number/select/etc... */
    const isTypeTheKeyOfField = R.is(Object, schemaType)
    /**
     * This can be the root form schema, the `nested_schema` or `children`.
     * None of these objects has a `schemaType` as they are simply containers for the items that define the type
    */
    const isSchemaContainer = (R.isNil(schemaType) || isTypeTheKeyOfField)
    if (isSchemaContainer) {
      if (Array.isArray(schema)) {
        const previousItem = item => previousSchema.find(value => value.name === item.name)
        return Object.fromEntries(
          schema.map(
            item => [
              item.name,
              createFormModel(item, previousItem(item), persistedModel, shouldUseDefaultValues, [...keys, item.name])],
          ),
        )
      }
      if (R.is(Object, schema)) {
        const map = (value, key) => createFormModel(value, previousSchema[key], persistedModel, shouldUseDefaultValues, [...keys, key])
        return R.mapObjIndexed(map, schema)
      }
    }

    const previousValue = R.path(keys, persistedModel)
    const hasDefaultSchema = !R.isNil(schema.default)
    shouldUseDefaultValues = shouldUseDefaultValues || R.isNil(previousValue)
    if (hasDefaultSchema && shouldUseDefaultValues) {
      return schema.default
    }

    if (schemaType === SchemaFormModelTypes.ExpandingList) {
      return createExpandingList(schema.nested_schema, previousSchema.nested_schema, persistedModel, shouldUseDefaultValues, keys)
    }
    if (schemaType === SchemaFormModelTypes.NestedObject) {
      return createFormModel(schema.children, previousSchema.children, persistedModel, shouldUseDefaultValues, keys)
    }

    /** This happens when changing from/to normal and advanced schema */
    const isChangingCodeType = schemaType === SchemaFormModelTypes.Code || previousSchema.type === SchemaFormModelTypes.Code
    const hasSchemaTypeChanged = schemaType !== previousSchema.type
    if (isChangingCodeType || !hasSchemaTypeChanged) {
      return previousValue
    }

    return null
  }

  function createExpandingList(schema, previousSchema, persistedModel, shouldUseDefaultValues, keys) {
    if (!schema) return []

    try {
      const nullishKeys = Object.keys(schema).reduce((acc, curr) => {
        acc[curr] = null
        return acc
      }, {})

      const persistedSubModel = R.path(keys, persistedModel)

      const hasPersistedSubModelArray = !R.isNil(persistedSubModel) && Array.isArray(persistedSubModel)
      if (hasPersistedSubModelArray) {
        persistedSubModel.forEach((item, index) => {
          persistedSubModel[index] = { ...nullishKeys, ...item }
        })
        return persistedSubModel
      }
    } catch (error) {
      error.message = `${i18n.t('error.expandListSchema')}\n${error.message}`
      throw error
    }

    return [createFormModel(schema, previousSchema, persistedModel, shouldUseDefaultValues, keys)]
  }

  return {
    createFormModel,
  }
}
